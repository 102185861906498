<template>
  <v-container fluid>
    <v-row class="top_btn mx-auto">
      <v-btn outlined class="mr-2 summary_btn rounded-pill elevation-1"
        id="control_summary"
        ref="control_summary"
        style="height:35px; width:110px; font-size:14px; letter-spacing: -0.5px;"
        @click="gotoSummary()">
        ◀︎ 제어모니터링
      </v-btn>
      <v-btn outlined class="history_btn rounded-pill elevation-1"
        id="histories"
        ref="histories"
        style="height:35px; width:110px; font-size:14px; letter-spacing: -0.5px;"
        :loading="historiesloading"
        @click="showHistories()">
        {{historiesBtnText}}
      </v-btn>
    </v-row>
    <v-layout wrap justify-center>
      <v-card id="info" outlined>
        <div id="roominfo">
          <div id="textArea">
            <div v-show=false> {{company_cd}}{{company_name}}{{farm_cd}}{{farm_name}}{{building_cd}}{{building_name}}{{room_cd}} </div>
            <div class="text_name"> {{room_name}}
              <span class="text_staus"> {{auto_mode}} </span>
            </div>
            <div>
              <img class="box_temp" :src="icon_controltemp_white"/>
              <div class="temp">
                <span class="text_temp">온도/습도</span>
                <span class="text_number">{{temp}}</span>
                <span class="text_number_unit"> ℃ /</span>
                <span class="text_number ml-1">{{hum}}</span>
                <span class="text_number_unit"> %</span>
              </div>

              <div class="feel">
                <span class="text_feel">체감온도</span>
                <span class="text_number">{{feel}}</span>
                <span class="text_number_unit"> ℃ </span>
              </div>

              <div class="content">
                <div class="set_info">
                  <div class="name">
                    <v-icon color=#3a4f3f size="20">
                        mdi-fan
                    </v-icon>
                    팬
                  </div>
                  <div class="detail_info">
                    <div class="text_info"> 목표온도 : {{pannel.set_temp}}</div>
                    <div class="text_info">온도편차 : {{pannel.dev_temp}}</div>
                  </div>
                </div>

                <div class="set_info">
                  <div class="name">보온등</div>
                  <div class="detail_info">
                    <div class="text_info"> 목표온도 : {{pannel.set_temp1 }}</div>
                    <div class="text_info"> 온도편차 : {{pannel.dev_temp1}}</div>
                  </div>
                </div>

                <div style=" padding:15px 15px 0 15px;">
                  <div style="display:flex;">
                    <div class="name">현재출력</div>
                    <div class="text_info"> ID | 1차 / 2차 / 3차 %</div>
                  </div>
                  
                  <div class="detail_info" >
                    <!-- <div class="text_info"> ID | 1차 / 2차 / 3차 %</div> -->
                    <div v-for="fan_list in pannel.now_list" :key="fan_list.slave_id">
                      <div style="display:flex;">
                        <!-- {{ fan_list }} -->
                        <div style="display:flex;">
                          <div v-if="fan_list.now_vent === `-∕-∕-` " style="width:65px;"></div>

                          <div class="fc" v-if="fan_list.now_vent1 !== '-'">
                            <img :src="icon_fancontrol" v-if="fan_list.now_vent1 == 0" />
                            <img :src="icon_fancontrol" v-else :style="{ '--animation-duration' : (2 - (fan_list.now_vent1 / 100 * 1.9))  + 's' }" class="test_vent" />
                          </div>
                          <div class="fc ml-1" v-if="fan_list.now_vent2 !== '-'">
                            <img :src="icon_fancontrol" v-if="fan_list.now_vent2 == 0" />
                            <img :src="icon_fancontrol" v-else :style="{ '--animation-duration' : (2 - (fan_list.now_vent2 / 100 * 1.9))  + 's' }" class="test_vent" />
                          </div>
                          <div class="fc ml-1" v-if="fan_list.now_vent3 !== '-'">
                            <img :src="icon_fancontrol" v-if="fan_list.now_vent3 == 0" />
                            <img :src="icon_fancontrol" v-else :style="{ '--animation-duration' : (2 - (fan_list.now_vent3 / 100 * 1.9))  + 's' }" class="test_vent" />
                          </div>
                        </div>
                      <div class="text_inf ml-5"> 
                        <span class="mr-1">{{fan_list.slave_id}}</span>  |  
                        <span class="ml-2">{{fan_list.now_vent}} </span>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="set_info">
                  <div class="name" >
                    주령
                  </div>
                  <div class="detail_info">
                    <div class="text_info"> {{week_age}} 주</div>
                  </div>
                </div>
              
                <div class="set_info">
                  <div class="name">
                    환기량
                  </div>
                  <div class="detail_info">
                    <div class="text_info"> - </div>
                  </div>
                </div>
              </div>
              
              <div class="control_date">적용일시:
                <span class="enter_date">{{setdate}}</span>
              </div>
            </div>
          </div>
        </div>

        <v-card id="controlset" outlined :loading="loadingInfoDetail">
          
          <div class="set_mode">
              <div class="set_select">
                <v-radio-group dense outlined row @change="changeValue()"
                  id="remote_mode"
                  v-model="remote_mode">
                  <template v-slot:label>
                    <div class="set_name"> 제어모드 </div>
                  </template>
                  <v-radio ref="remote1" label="수동" value="1" color="#3a4f3f"/>
                  <v-radio ref="remote2" label="계절" value="2" color="#88191a"/>    
                </v-radio-group>
            </div>
          </div>

          <div class="set_mode">
              <div class="set_name">제어기준지표</div>
              <div class="set_select">
                <v-select dense outlined class="selectbox shrink" @change="changeValue()"
                  id="control_stand"
                  ref="control_stand"
                  v-model="control_stand"
                  :items="standardItems"
                  :menu-props="{top:false, offsetY:true}"
                  no-data-text="권한이 없습니다."
                  item-text="name"
                  return-object/>
            </div>
          </div>

          <div class="set_mode" v-show="remote_mode === '2'">
              <div class="set_name">체중구간</div>
              <div class="set_select">
                <v-select dense outlined class="selectbox shrink" @change="changeValue()"
              id="weight_section"
              ref="weight_section"
              v-model="weight_section"
              :items="weightItems"
              :menu-props="{top:false, offsetY:true}"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @focus="weightSets()"/>
            </div>
          </div>

          <div class="set_mode">
              <div class="set_name">출생일</div>
              <div class="set_select">
              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="10"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense outlined clearable class="selectbox shrink" @change="changeValue()"
                  id="birth" 
                  ref="birth" 
                  v-model="birth"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                no-title locale="ko-KR"
                v-model="birth"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
            </div>
          </div>

          <v-textarea class="ml-2 mr-2" outlined dense rows="2" name="input-7-4" @change="changeValue()"
            label="비고"
            id="history" 
            ref="history" 
            v-model="history"
            value=""
          ></v-textarea>
            


            <!-- <v-row  v-show="remote_mode === '2'">
              <span class="text_diurnal"> 일교차 관리 </span>
              <div class="diurnal_set">
                <v-radio-group dense outlined row @change="changeValue()"
                  id="daily_range_yn"
                  v-model="daily_range_yn">
                  <v-radio ref="daily_range_y" label="ON" value="Y" class="ml-3 mr-8" color="#3a4f3f"/>
                  <v-radio label="OFF" value="N" class="ml-0" color="#88191a"/>    
                </v-radio-group>
              </div>
            </v-row>

            <v-row v-show="daily_range_yn === 'Y' ">
              <v-subheader class="text_daily"> 일교차 한도 </v-subheader>
              <v-text-field dense outlined @change="changeValue()"
                type="number"
                id="daily_range"
                ref="daily_range"
                class="daily_set shrink"
                v-model="daily_range"
                min="0"
                max="35"
                oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                maxlength="2"/>
            </v-row> -->

        </v-card>
      </v-card>
      <v-form id="remoteSet">
        <div v-for="(fan_list,i) in fan_list" :key="i">
          <v-subheader class="fan_group" style="font-size:17px; font-weight:bold;">팬컨트롤러 {{i + 1}}</v-subheader>
          <div dense class="fc_setbox">
            <v-layout wrap class="fc_detail">
              <v-subheader style="font-size:17px; font-weight:bold;" class="mr-5">1차</v-subheader> 
            
              <v-text-field dense outlined style="margin-bottom: -12px;"  @change="changeValue()"
                  type="number"
                  id="min_vent1"
                  ref="min_vent1"
                  class="min_vent1 centered-input mr-3 shrink"
                  label="최소출력"
                  min="0"
                  max="99"
                  :disabled="remote_mode!=='1'"
                  v-model="fan_list.min_vent1"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  >                
              </v-text-field>

              <v-text-field dense outlined style="margin-bottom: -12px;" @change="changeValue()"
                  type="number"
                  id="max_vent1"
                  ref="max_vent1"
                  class="max_vent1 mr-3 centered-input shrink"
                  label="최대출력"
                  min="0"
                  max="99"
                  :disabled="remote_mode!=='1'"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan_list.max_vent1"/>

              <v-text-field dense outlined style="margin-bottom: -12px;" @change="changeValue()"
                  type="number"
                  id="set_temp1"
                  ref="set_temp1"
                  class="set_temp1 centered-input mr-3 shrink"
                  label="설정온도"
                  min="15"
                  max="33"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  :disabled="remote_mode!=='1'"
                  v-model="fan_list.set_temp1"/>

              <v-text-field dense outlined style="margin-bottom: -12px;" @change="changeValue()"
                  type="number"
                  id="dev_temp1"
                  ref="dev_temp1"
                  class="dev_temp1 centered-input mr-5 shrink"
                  label="온도편차"
                  min="0"
                  max="10"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  :disabled="remote_mode!=='1'"
                  v-model="fan_list.dev_temp1"/>
            </v-layout>
          </div>

          <div dense class="fc_setbox">
            <v-layout wrap class="fc_detail">
              <v-subheader style="font-size:17px; font-weight:bold;" class="mr-5">2차</v-subheader> 
            
              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="min_vent2"
                  ref="min_vent2"
                  class="min_vent2 centered-input mr-3 shrink" style="margin-bottom: -12px;"
                  label="최소출력"
                  min="0"
                  max="99"
                  :disabled="remote_mode!=='1'"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan_list.min_vent2">                    
              </v-text-field>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="max_vent2"
                  ref="max_vent2"
                  class="max_vent2 centered-input mr-3 shrink" style="margin-bottom: -12px;"
                  label="최대출력"
                  min="0"
                  max="99"
                  :disabled="remote_mode!=='1'"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan_list.max_vent2"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="set_temp2"
                  ref="set_temp2"
                  class="set_temp2 centered-input mr-3 shrink" style="margin-bottom: -12px;"
                  label="설정온도"
                  min="15"
                  max="33"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  :disabled="remote_mode!=='1'"
                  v-model="fan_list.set_temp2"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="dev_temp2"
                  ref="dev_temp2"
                  class="dev_temp2 centered-input mr-5 shrink" style="margin-bottom: -12px;"
                  label="온도편차"
                  min="0"
                  max="10"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  :disabled="remote_mode!=='1'"
                  v-model="fan_list.dev_temp2"/>
            </v-layout>
          </div>

          <div dense class="fc_setbox">
            <v-layout wrap class="fc_detail">
              <v-subheader style="font-size:17px; font-weight:bold;" class="mr-5">3차</v-subheader> 
            
              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="min_vent3"
                  ref="min_vent3"
                  class="min_vent3 centered-input mr-3 shrink" style="margin-bottom: -12px;"
                  label="최소출력"
                  min="0"
                  max="99"
                  :disabled="remote_mode!=='1'"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan_list.min_vent3">                    
              </v-text-field>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="max_vent3"
                  ref="max_vent3"
                  class="max_vent3 centered-input mr-3 shrink" style="margin-bottom: -12px;"
                  label="최대출력"
                  min="0"
                  max="99"
                  :disabled="remote_mode!=='1'"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="fan_list.max_vent3"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="set_temp3"
                  ref="set_temp3"
                  class="set_temp3 centered-input mr-3 shrink" style="margin-bottom: -12px;"
                  label="설정온도"
                  min="15"
                  max="33"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  :disabled="remote_mode!=='1'"
                  v-model="fan_list.set_temp3"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="dev_temp3"
                  ref="dev_temp3"
                  class="dev_temp3 centered-input mr-5 shrink" style="margin-bottom: -12px;"
                  label="온도편차"
                  min="0"
                  max="10"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  :disabled="remote_mode!=='1'"
                  v-model="fan_list.dev_temp3"/>
            </v-layout>
          </div>
        </div>

        <div v-for="(lamp_list,i) in lamp_list" :key="i + fan_list.length">
          <v-layout>
            <v-subheader class="lamp_group" style="font-size:17px; font-weight:bold;">보온등 {{i+1}}</v-subheader>
            <v-switch dense v-model="lamp_list.on_off1" style="margin-top:8px;" color="red darken-4" inset :disabled="remote_mode!=='1'" @change="changeValue()">
              <template #prepend>
                <v-label><span class="lamp_off">OFF</span></v-label>
              </template>
              <template #append>
                <v-label><span class="lamp_on">ON</span></v-label>
              </template>
            </v-switch>
          </v-layout>

          <div dense class="fc_setbox" v-show="lamp_list.on_off1 == true ">
            <v-layout wrap class="heat_detail">
              <v-subheader style="font-size:17px; font-weight:bold;" class="mr-5">1차</v-subheader> 

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="min_vent1"
                  ref="min_lamp1"
                  class="heat_min centered-input mr-3 shrink" style="margin-bottom: -12px;"
                  label="최소출력"
                  min="0"
                  max="99"
                  :disabled="remote_mode!=='1'"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="lamp_list.min_vent1"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="max_vent1"
                  ref="max_lamp1"
                  class="heat_max centered-input mr-3 shrink" style="margin-bottom: -12px;"
                  label="최대출력"
                  min="0"
                  max="99"
                  :disabled="remote_mode!=='1'"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="lamp_list.max_vent1"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="set_temp1"
                  ref="set_lamp1"
                  class="heat_settemp centered-input mr-3 shrink" style="margin-bottom: -12px;"
                  label="설정온도"
                  min="15"
                  max="33"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  :disabled="remote_mode!=='1'"
                  v-model="lamp_list.set_temp1"/>

              <v-text-field dense outlined @change="changeValue()"
                  type="number"
                  id="dev_temp1"
                  ref="dev_lamp1"
                  class="heat_tempdev centered-input mr-5 shrink" style="margin-bottom: -12px;"
                  label="온도편차"
                  :disabled="remote_mode!=='1'"
                  min="0"
                  max="10"
                  oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"
                  v-model="lamp_list.dev_temp1"/>
            </v-layout>
          </div>
        </div>
        

        <v-row class="setdetail" justify= "center" >
          <v-btn outlined class="exit_btn rounded-pill mr-3 elevation-3"
            id="exit"
            ref="exit"
            style="height:45px; width:90px; font-size:17px;"
            @click="$router.go(-1);"
            >
            취소
          </v-btn>
          <v-btn v-if="$getters.userInfo.grade < 'UT06'" outlined class="save_btn rounded-pill mr-3 elevation-3"
            :loading="loadingSave"
            id="save"
            ref="save"
            style="height:45px; width:90px; font-size:17px;"
            @click="save()">
            적용
          </v-btn>
          <v-btn v-if="$getters.userInfo.grade < 'UT06'" outlined class="stop_btn rounded-pill mr-3 elevation-3" v-show="pre_mode > 0"
            id="stop"
            ref="stop"
            style="height:45px; width:90px; font-size:17px;"
            @click="stopControl();"
            >
            제어중단
          </v-btn>
          <!-- <v-btn outlined class="history_btn rounded-pill elevation-3"
            id="histories"
            ref="histories"
            style="height:45px; width:90px; font-size:17px;"
            :loading="historiesloading"
            @click="showHistories()">
            {{historiesBtnText}}
          </v-btn> -->
        </v-row>

      </v-form>
    </v-layout >
    <v-layout wrap justify-center v-show="showhistories">
          <v-data-table style="width:845px" dense class="mt-5 elevation-3" 
            :loading="historiesloading"
            id="items" 
            ref="items"
            :headers="headers"
            :items="items"
            :items-per-page= "100"
            no-data-text="등록된 자료가 없습니다.  "
            fixed-header
            height="auto"
            hide-default-footer
            item-key="id"
            single-select
            @click:row="infoSetting"
            :item-class="itemRowBackground"
          >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.daily_range_yn="{ item }">
            <span>{{ item.daily_range_yn == 'Y' ? 'ON' : 'OFF' }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.daily_range="{ item }">
            <span>{{ item.daily_range_yn == 'Y' ? item.daily_range : 'N/A' }}</span>
          </template>
        
        
          </v-data-table>
    </v-layout>
  </v-container>
</template>

<script>
import Apis from '@/api/apis';
import Common from "@/utils/custom/common.js";
import dateUtil from "@/utils/custom/dateProperty.js";
// import VueCookies from "vue-cookies";

export default{
  name:"ControlSet",

  async created(){
      Apis.pageCount({ //페이지뷰 카운터
        page_name: this.$route.name,
        url: this.$route.path,
        division: "pigro",
      },() => {  // 정상처리
        // console.log("pageCount 호출",res);
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("pageCount 호출 오류",err);
      });
      if (!this.$route.params.room) {
            this.$router.push({
              name: "ControlSummary",
            });
            return;
      }
      await this.weightSets();
      this.infoDetail();
      // this.listHistories();
  },

  data : () => ({
      icon_controltemp_white: require("@/assets/barometer/icon_controltemp_white.svg"),
      icon_fancontrol: require("@/assets/monitoring/fan_control.svg"),

      now_vent:95,
      now_vent2:40,
      now_vent3:20,

      company_cd:"",farm_cd:"",building_cd: "",building_name: "",room_cd:"",    // 키값 No show
      company_name:"",farm_name:"",    // 키값 No show
      room_name:"",
      pannel:{},
      auto_mode:"",
      pre_mode: 0,
      temp:"",
      hum:"",
      feel:"",
      setdate:"",
      week_age:"",
      birth:"",
      menu1:false,

      enter_date:"",
      history:"",

      remote_mode:"",

      daily_range_yn: "",
      daily_range:"",

      fan_list:[{set_temp:"",dev_temp:""}],
      lamp_list:[{set_temp:"",dev_temp:""}],

      control_stand:{},
      standardItems : Common.comboBaseCode("DN"),

      weight_section:{},
      weightItems: [],

      items: [],
      headers: [
        { text: '적용일', sortable: false, align: 'start', value: 'setdate', width: "200px", groupable: false, class: 'green darken-4 white--text' },
        { text: '제어모드', sortable: false, align: 'start', value: 'control_status_text', width: "100px", groupable: false, class: 'green darken-4 white--text'},
        { text: '기준지표', sortable: false, align: 'start', value: 'control_stand.name', width: "150px", class: 'green darken-4 white--text' },
        { text: '체중구간', sortable: false, align: 'start', value: 'weight_range.name', width: "80px", groupable: false, class: 'green darken-4 white--text',},
        // { text: '일교차', sortable: false, align: 'center', value: 'daily_range_yn', width: "100px" , groupable: false, class: 'green darken-4 white--text'},
        // { text: '한도', sortable: false, align: 'center', value: 'daily_range', width: "100px" , groupable: false, class: 'green darken-4 white--text'},
        { text: '출생일', sortable: false, align: 'center', value: 'birthday', width: "150px", groupable: false, class: 'green darken-4 white--text' },
        { text: '등록자', sortable: false, align: 'start', value: 'reguser', width: "200px", groupable: false, class: 'green darken-4 white--text' },
        { text: '비고', sortable: false, align: 'start', value: 'history', width: "200px" , groupable: false, class: 'green darken-4 white--text'},
      ],
      loadingInfoDetail:false,
      historiesloading:false,
      showhistories:false,
      historiesBtnText: "이력보기",
      save_id:0,
      loadingSave:false, 

    }),


  methods : {
    changeValue(){
      this.$store.commit("resMessage","");
    },
    weightSet(){

    },
    async weightSets(){
      await Apis.comboWeightRanges({
        building_cd: this.$route.params.building,
        },(res) => {  
          if (res.result) {
            this.weightItems = res.data;
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboWeightRanges API 호출 오류",err)
        }
      )
    },
    clearData() {
      this.remote_mode = "";
      this.pre_mode = 0;
      this.control_stand = {};
      this.weight_section = {};
      // this.daily_range_yn = "";
      this.birth = "";
      this.history = "";
    }, 
    checkData() {
      if (this.remote_mode < "1") {
        this.$refs.remote1.$el.children[1].focus()
        // this.$refs.control_stand.focus();
        return "제어모드를 선택하세요";
      }
      if (!this.control_stand.code) {
        this.$refs.control_stand.focus();
        return "제어기준지표를 선택하세요";
      }
      if (this.remote_mode == "2") {
        if (!this.weight_section.code) {
          this.$refs.weight_section.focus();
          return "체중구간을 선택하세요";
        }
      }
      // if (this.remote_mode == "2") {
      //   if (!this.daily_range_yn) {
      //     this.$refs.daily_range_y.$el.children[1].focus()
      //     return "일교차관리 모드를 선택하세요";
      //   }
      // }
      // if (this.daily_range_yn == "Y") {
      //   if (!Common.isNumeric(this.daily_range) || this.daily_range < 1 || this.daily_range > 20) {
      //     this.$refs.daily_range.focus();
      //     return "일교차 한도를 입력하세요";
      //   }
      // }
      if (this.remote_mode == "1") {
        for (let i in this.fan_list) {
          // console.log(i,this.fan_list[i].max_vent1);
          if (!Common.isNumeric(this.fan_list[i].max_vent1) || this.fan_list[i].max_vent1 < 0 || this.fan_list[i].max_vent1 > 99 ) {
            this.$refs.max_vent1[i].focus();
            return "정확한 값을 입력하세요(0~99)";
          }
          if (!Common.isNumeric(this.fan_list[i].max_vent2) || this.fan_list[i].max_vent2 < 0 || this.fan_list[i].max_vent2 > 99 ) {
            this.$refs.max_vent2[i].focus();
            return "정확한 값을 입력하세요(0~99)";
          }
          if (!Common.isNumeric(this.fan_list[i].max_vent3) || this.fan_list[i].max_vent3 < 0 || this.fan_list[i].max_vent3 > 99 ) {
            this.$refs.max_vent3[i].focus();
            return "정확한 값을 입력하세요(0~99)";
          }
          if (!Common.isNumeric(this.fan_list[i].min_vent1) || this.fan_list[i].min_vent1 < 0 || this.fan_list[i].min_vent1 > 99 ) {
            this.$refs.min_vent1[i].focus();
            return "정확한 값을 입력하세요(0~99)";
          }
          if (!Common.isNumeric(this.fan_list[i].min_vent2) || this.fan_list[i].min_vent2 < 0 || this.fan_list[i].min_vent2 > 99  ) {
            this.$refs.min_vent2[i].focus();
            return "정확한 값을 입력하세요(0~99)";
          }
          if (!Common.isNumeric(this.fan_list[i].min_vent3) || this.fan_list[i].min_vent3 < 0 || this.fan_list[i].min_vent3 > 99 ) {
            this.$refs.min_vent3[i].focus();
            return "정확한 값을 입력하세요(0~99)";
          }
          if (!Common.isNumeric(this.fan_list[i].set_temp1) || this.fan_list[i].set_temp1 < 15 || this.fan_list[i].set_temp1 > 33) {
            this.$refs.set_temp1[i].focus();
            return "정확한 값을 입력하세요(15~33)";
          }
          if (!Common.isNumeric(this.fan_list[i].set_temp2) || this.fan_list[i].set_temp2 < 15 || this.fan_list[i].set_temp2 > 33) {
            this.$refs.set_temp2[i].focus();
            return "정확한 값을 입력하세요(15~33)";
          }
          if (!Common.isNumeric(this.fan_list[i].set_temp3) || this.fan_list[i].set_temp3 < 15 || this.fan_list[i].set_temp3 > 33) {
            this.$refs.set_temp3[i].focus();
            return "정확한 값을 입력하세요(15~33)";
          }
          if (!Common.isNumeric(this.fan_list[i].dev_temp1) || this.fan_list[i].dev_temp1 < 0 || this.fan_list[i].dev_temp1 > 10) {
            this.$refs.dev_temp1[i].focus();
            return "정확한 값을 입력하세요(0~10)";
          }
          if (!Common.isNumeric(this.fan_list[i].dev_temp2) || this.fan_list[i].dev_temp2 < 0 || this.fan_list[i].dev_temp2 > 10) {
            this.$refs.dev_temp2[i].focus();
            return "정확한 값을 입력하세요(0~10)";
          }
          if (!Common.isNumeric(this.fan_list[i].dev_temp3) || this.fan_list[i].dev_temp3 < 0 || this.fan_list[i].dev_temp3 > 10) {
            this.$refs.dev_temp3[i].focus();
            return "정확한 값을 입력하세요(0~10)";
          }
        }
      }
      if (this.lamp_list.on_off1 == true) {
        for (let i in this.lamp_list) {
          // console.log(i,this.fan_list[i].max_vent1);
          if (!Common.isNumeric(this.lamp_list[i].max_vent1) || this.lamp_list[i].max_vent1 < 0 || this.lamp_list[i].max_vent1 > 99 ) {
            this.$refs.max_lamp1[i].focus();
            return "정확한 값을 입력하세요(0~99)";
          }
          if (!Common.isNumeric(this.lamp_list[i].min_vent1) || this.lamp_list[i].min_vent1 < 0 || this.lamp_list[i].min_vent1 > 99 ) {
            this.$refs.min_lamp1[i].focus();
            return "정확한 값을 입력하세요(0~99)";
          }
          if (!Common.isNumeric(this.lamp_list[i].set_temp1) || this.lamp_list[i].set_temp1 < 15 || this.lamp_list[i].set_temp1 > 33) {
            this.$refs.set_lamp1[i].focus();
            return "정확한 값을 입력하세요(15~33)";
          }
          if (!Common.isNumeric(this.lamp_list[i].dev_temp1) || this.lamp_list[i].dev_temp1 < 0 || this.lamp_list[i].dev_temp1 > 10) {
            this.$refs.dev_lamp1[i].focus();
            return "정확한 값을 입력하세요(0~10)";
          }
        }
      }
      return "";
    },
    save(){
      this.$store.commit("resMessage","");
      let msgCheckdata = this.checkData(); 
      if (msgCheckdata){
        this.$store.commit("resMessage",msgCheckdata);
        // alert(msgCheckdata);
        return;
      }
      if (!confirm("설정변경 확인! \n 설정을 적용하시겠습니까?")) { return; }
      else {
        this.loadingSave = true;
        Apis.saveControllerSetting({
          company_cd: this.company_cd,
          farm_cd: this.farm_cd,
          building_cd: this.building_cd, building_name: this.building_name,
          room_cd: this.room_cd, 
          room_name: this.room_name,
          // fan_list: this.remote_mode && this.remote_mode == 1 && this.fan_list || [],
          // lamp_list: this.remote_mode && this.remote_mode == 1 && this.lamp_list || [],
          fan_list: this.fan_list,
          lamp_list: this.lamp_list,
          setdate: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          control_status: this.remote_mode && Number(this.remote_mode) || 0,
          control_stand: this.control_stand.code && this.control_stand.code || "",
          weight_range: this.remote_mode == "2" && this.weight_section && Number(this.weight_section.code) || 0,
          daily_range_yn: this.daily_range_yn || "",
          daily_range: this.daily_range && Number(this.daily_range) || 0,
          birthday: this.birth && this.birth || "",
          history: this.history && this.history.trim() || "",
          },(res) => {  
            if (res.result) {
              this.loadingSave = false;
              alert(res.message);
              this.$router.go(-1);
            } else {
              this.loadingSave = false;
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
            this.loadingSave = false;
            console.log("saveControllerSetting API 호출 오류",err)
            this.$store.commit("resMessage","saveControllerSetting API 호출 오류");
          }
        ) 
      }
    },
    stopControl(){
      this.$store.commit("resMessage","");
      this.loadingSatop = true;
      if (!confirm("제어중단 확인 !!! \n 중앙제어를 중단하고 현장제어로 적용하시겠습니까?")) { return; }
      else {

        Apis.saveControllerSetting({
          company_cd: this.company_cd,
          farm_cd: this.farm_cd,
          building_cd: this.building_cd, building_name: this.building_name,
          room_cd: this.room_cd, room_name: this.room_name,
          fan_list: this.fan_list,
          lamp_list: this.lamp_list,
          setdate: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          control_status: 0,
          control_stand: "",
          weight_range: 0,
          daily_range_yn: "",
          daily_range: 0,
          birthday: this.birth && this.birth || "",
          history: this.history && this.history.trim() || "",
          },(res) => {  
            if (res.result) {
              this.$router.go(-1);
            } else {
              alert(res.message);
            }
          }).catch( (err) => {  // API 오류 처리
            console.log("saveControllerSetting API 호출 오류",err)
            this.$store.commit("resMessage","saveControllerSetting API 호출 오류");
          }
        ) 
      }
    },

    infoDetail(){
      this.loadingInfoDetail= true;
      Apis.infoDetailRoomControllers(
        {
          room_cd: this.$route.params.room,
          // room_cd: "R20007",
          stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
            if (res.result) {
              this.company_cd = res.data.company_cd; this.company_name = res.data.company_name;
              this.farm_cd = res.data.farm_cd; this.farm_name = res.data.farm_name;
              this.building_cd = res.data.building_cd; this.building_name = res.data.building_name;
              this.building_name = res.data.building_name;
              this.room_cd = res.data.room_cd;
              this.room_name = res.data.room_name;
              this.pannel = {
                              set_temp: res.data.fan_list[0].set_temp, dev_temp: res.data.fan_list[0].dev_temp,
                              set_temp1: res.data.lamp_list[0] && res.data.lamp_list[0].set_temp1 || "" ,
                              dev_temp1: res.data.lamp_list[0] && res.data.lamp_list[0].dev_temp1 || "" ,
                              now_list: res.data.fan_list,
                            };
              res.data.room_name;
              this.auto_mode = res.data.auto_mode;
              this.pre_mode = res.data.control_status;
              this.setdate = res.data.setdate.substring(0,19);
              this.remote_mode = res.data.control_status.toString();
              this.temp = res.data.temp;
              this.hum = res.data.hum;
              this.feel = res.data.feel;
              this.week_age = res.data.week_age;
              this.enter_date = res.data.enter_date;
              this.birth = res.data.birthday;
              // this.history = res.data.history;

              this.control_stand = res.data.control_stand; 
              this.weight_section = res.data.weight_range;

              // this.daily_range_yn = res.data.daily_range_yn;
              // this.daily_range = res.data.daily_range;
              
              this.fan_list = res.data.fan_list;
              this.lamp_list = res.data.lamp_list && res.data.lamp_list || [];
              this.loadingInfoDetail= false;
          } else {  
              this.loadingInfoDetail= false;
              alert(res.message);
            }
            // console.log(this.pannel.now_list);
          }
        ).catch( (err) => {  // API 오류 처리
            this.loadingInfoDetail= false;
            this.$router.push({
              name: "ControlSummary",
            });
            console.log("API 호출 오류",err)
            // alert(err);
        }
      ) 
    },
    infoSetting(value,row) {
      if (value.control_status !== 1) return;
      row && row.select(true);
      this.$store.commit("resMessage","");
      this.loadinginfoDetail = true;
      this.clearData();
      Apis.infoSettingHistory({
        id: value.id, 
        },(res) => {  
          if (res.result) {
            this.remote_mode = res.data.control_status.toString();
            this.birth = res.data.birthday;
            this.control_stand = res.data.control_stand; 
            this.weight_section = res.data.weight_range;

            // this.daily_range_yn = res.data.daily_range_yn;
            // this.daily_range = res.data.daily_range;
            this.history = res.data.history;
            
            this.fan_list = res.data.fan_list;
            this.lamp_list = res.data.lamp_list;
            
            this.loadinginfoDetail = false;
            this.$store.commit("resMessage",`${res.data.setdate} 수동세팅 내용입니다.`);
            window.scrollTo(0,0);
          } else {
            alert(res.message);
            this.loadinginfoDetail = false;
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("API 호출 오류",err)
            this.loadinginfoDetail = false;
            // alert(err);
        }
      ); 
    },
    listHistories() {
      // if (this.msgFlag) this.$store.commit("resMessage","");
      this.save_id = 0;
      this.historiesloading = true;
      Apis.listSettingHistories({
          room_cd: this.room_cd,
      } ,(res) => {  // 정상처리
        if (res.result) {
          this.items = res.data;
          // if (this.msgFlag) this.$store.commit("resMessage",res.message);
          // this.msgFlag = true;
          this.historiesloading = false;
        } else {
          console.log("listSettingHistories 자료없슴",res.mag);
          this.historiesloading = false;
        }

      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listSettingHistories 호출 오류",err);
        this.historiesloading = false;
      }) 
    }, 
    showHistories() {
      this.showhistories = ! this.showhistories;
      if (this.showhistories) this.listHistories();
      this.historiesBtnText = this.showhistories ? "이력닫기" : "이력보기";
    },
    itemRowBackground(item) {
      return item.id === this.save_id  ? 'selected' : '' ;
    },
    gotoSummary() {
      this.$router.push({
        name: "ControlSummary",
        params: {
          company: {code: this.company_cd, name: this.company_name},
          farm: {code: this.farm_cd, name: this.farm_name},
          building: {code: this.building_cd, name: this.building_name},
        }
      });
    },

  }
}
</script>

<style lang="scss" scoped >
.top_btn{
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin:0px 0px 5px 0px;
}
#info{
  border-color: transparent;
  height:100%;
}
#controlhistory{
  width:100%;
  margin-top:20px;
  height:auto;
}
#controlset{
  width:auto;
  margin-top:10px;
  height:100%;
}
#roominfo {
  width: 300px;
  padding-bottom: 10px;
  border-radius: 10px;
  text-align: center;
  background-color: #3a4f3f;
}

#fc_set{
  width:100%;
  margin:10px;
}


#textArea > div {
  font-size: 20px;
  font-weight: 500 !important;
  letter-spacing: -0.6px;
  line-height:15px;
  margin-left:7px;
  text-align: left;
  margin-right: 8px;
  color: white;
}

.text_name{
  padding:20px !important;
  font-weight:bold !important;
}

.text_staus{
  font-size:17px !important;
  margin-left:5px !important;
  color:#a09f9f;
}

.box_temp{
  margin-left:10px !important;
  margin-top:-10px;
}

.temp{
  margin-top:-47px;
  margin-left:50px;
  letter-spacing: -0.8px;
}

.feel{
  margin-left:50px;
  margin-top:5px;
  letter-spacing: -0.8px;
}

.text_temp{
  font-size:18px;
  font-weight: bold;
  margin-right:10px;
}

.text_feel{
  font-size:18px;
  font-weight: bold;
  margin-left:1px;
  margin-right:15px;
}

.text_number{
  font-size : 18px;
}
.text_number_unit{
  font-size:15px;
}

.content{
  background-color: whitesmoke;
  width:100%;
  height:100%;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-top:15px;
  margin-left:1px;
  border-radius: 10px;
  color:#3a4f3f !important;
}

.set_info{
  display: flex;
  padding:15px 15px 0 15px;
}

.name{
  font-size:18px !important;
  font-weight:bold;
  width:85px;
}

.text_info{
  font-size:18px;
  padding-bottom:5px;
}

.control_date{
  text-align: right;
  font-size:17px;
  color:#a3a3a3;
  margin-right:15px;
}


.set_mode{
  display: flex;
  height:65px;
  line-height:45px;
}

.set_name{
  padding-left:10px;
  // margin-right:10px;
  color: #808080 !important;
  font-size:17px !important;
  font-weight:bold;
  width:120px;
  letter-spacing: -1px;
}


.selectbox{
  width:170px;
}

.fc_setbox{
  margin-top:-10px;
  margin-bottom: 10px;
  margin-left:20px;
  padding-top: 2px;
  padding-bottom: 0px;
  border-radius: 10px;
  position: relative;
  display: flex;
  box-shadow: 0 1px 2px #a3a3a3;
}

.fc_detail{
  padding:5px;
  margin-top:2px;
  margin-bottom:-15px;
}


.min_vent1, .min_vent2, .min_vent3{
  width:80px;
}

.max_vent1, .max_vent2, .max_vent3{
  width:80px;
}

.set_temp1, .set_temp2, .set_temp3{
  width:80px;
}

.dev_temp1, .dev_temp2, .dev_temp3{
  width:80px;
}


.heat_detail{
  padding:5px;
  margin-top:5px;
  margin-bottom:-15px;
}

.heat_min, .heat_max, .heat_settemp, .heat_tempdev{
  width:75px;
}

.setdetail{
  margin-top:55px;
}
.fan_group{
  color:#3a4f3f !important;
}
.lamp_group{
  color:#88191a !important;
}
.lamp_off{
  font-size: 12px;
  margin-left: 10px;
  color:#b4b4b4;
}
.lamp_on{
  font-size: 12px;
  margin-left: -20px;
  color:#3a4f3f;
}
.exit_btn{
  color:#3a4f3f !important;
  font-weight: bold;
  border-color: #3a4f3f !important;
}

.save_btn{
  color:white !important;
  border-color:#3a4f3f !important;
  font-weight: bold;
  background-color: #3a4f3f !important;
}
.stop_btn{
  color:white !important;
  border-color:#88191a !important;
  font-weight: bold;
  background-color: #88191a !important;
}
.history_btn{
  color: #3a4f3f !important;
  border-color:#dbdbdb !important;
  font-weight: bold;
  background-color: #e9e6e6 !important;
}
.summary_btn{
  color: #3a4f3f !important;
  border-color:#dbdbdb !important;
  font-weight: bold;
  background-color: white !important;
}

.v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}

tr.v-data-table__selected {
  background: #edfcf0 !important;
}
.selected {
  background-color: #edfcf0 !important;
}
.fc{
  background-color:#3a4f3f;
  width:18px;
  // margin:px;
  padding:3px;
  border-radius:4px;
}
.fan_icon{
  width:13px;
}

//팬출력
.test_vent { animation: rotate_image var(--animation-duration) linear infinite; transform-origin: 50% 50%;}

@keyframes rotate_image{
    100% {
        transform: rotate(360deg);
    }
}

</style>
<style scoped>
    .centered-input >>> input {
      text-align: center ;
    }
</style>